'use client'

import {ThemeProvider, createTheme} from '@mui/material/styles'
import type {ReactNode} from 'react'

const primary = {
	main: '#a52822',
	light: '#fce8e6',
	dark: '#525252',
	contrastText: '#fff',
}

const theme = createTheme({
	palette: {
		primary: primary,
	},
})

export const MaterialTheme = ({children}: {children: ReactNode}) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MaterialTheme
